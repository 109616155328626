import { atom, getDefaultStore } from 'jotai'
import { GoogleMapBounds } from '../types'


const greeceBounds: GoogleMapBounds = {
  southwest: {
    lat: 34.802075,
    lng: 19.371601,
  },
  northeast: {
    lat: 41.748877,
    lng: 28.240020,
  },
}

const boundsAtom = atom<GoogleMapBounds>(greeceBounds)

const widgetModeAtom = atom<boolean>(false)
const store = getDefaultStore()

export function setWidgetMode() {
  store.set(widgetModeAtom, true)
}

export function setNormalMode() {
  store.set(widgetModeAtom, false)
}

export function getWidgetMode() {
  return store.get(widgetModeAtom)
}

export function getBounds() {
  return store.get(boundsAtom)
}

export function setBounds(bounds: GoogleMapBounds) {
  store.set(boundsAtom, bounds)
}
