import { SearchVehiclesQuery } from '../../../types'
import { AvailabilityAndPricing } from '../../../types/backendModels/availability'
import { Booking } from '../../../types/backendModels/booking'
import {
  mkAddedPersonalInfoValue,
  mkPurchaseValue,
  mkViewAvailabilitiesEventValue,
  mkViewSingleAvailabilityEventValue,
} from './functions'
import { Event, EventKey, StandardEventKey, CustomEventKey, PaymentFailurePayload } from './types'
import { FB_PIXEL } from '../../../globals'

type ReactPixelT = typeof import('react-facebook-pixel')

class FbPixel {
  private static instance: FbPixel

  static async mkFb(): Promise<ReactPixelT> {
    const { default: ReactPixel } = await import('react-facebook-pixel')
    ReactPixel.init(FB_PIXEL, null, {
      autoConfig: true,
      debug: true,
    })
    return ReactPixel
  }

  private fb: ReactPixelT

  private constructor(fb: ReactPixelT) {
    this.fb = fb
  }

  public static async getInstance(): Promise<FbPixel> {
    if (!FbPixel.instance) {
      const fb = await FbPixel.mkFb()
      FbPixel.instance = new FbPixel(fb)
    }
    return FbPixel.instance
  }

  event<K extends EventKey>({ event, value }: Event<K>) {
    if (typeof window !== 'undefined') {
      if (
        event === 'ViewAvailabilities' ||
        event === 'ViewSingleAvailability' ||
        event === 'AddedPersonalInfo' ||
        event === 'PaymentFailed'
      ) {
        this.fb.trackCustom(event, value)
      } else {
        this.fb.track(event, value)
      }
    } else {
      console.warn('No window for FB event:', event, ' value:', value)
    }
  }

  searchEvent(query: SearchVehiclesQuery) {
    this.event({ event: 'Search', value: { search_string: JSON.stringify(query) } })
  }

  viewItemEvent(searchAvailabilities: AvailabilityAndPricing[]) {
    const vcv = mkViewAvailabilitiesEventValue(searchAvailabilities)
    this.event({ event: 'ViewAvailabilities', value: vcv })
  }

  selectItemEvent(searchAvailability: AvailabilityAndPricing) {
    const vcv = mkViewSingleAvailabilityEventValue(searchAvailability)
    this.event({ event: 'ViewSingleAvailability', value: vcv })
  }

  beginCheckoutEvent(booking: Booking) {
    const icv = mkAddedPersonalInfoValue(booking)
    this.event({
      event: 'AddedPersonalInfo',
      value: icv,
    })
  }

  purchaseEvent(booking: Booking) {
    const pv = mkPurchaseValue(booking)
    this.event({
      event: 'Purchase',
      value: pv,
    })
  }

  purchaseFailureEvent(failedBooking: PaymentFailurePayload) {
    this.event({
      event: 'PaymentFailed',
      value: failedBooking,
    })
  }

  pageView() {
    this.fb.pageView()
  }
}

export default async function fb() {
  return FbPixel.getInstance()
}
