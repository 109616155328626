import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/legacy/image'
import LanguageSwitcher from '../../LanguageSwitcher'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'

const Navbar = ({ withoutBanner }) => {
  const { t, ready, i18n } = useTranslation()
  const [options, setOptions] = useState({
    collapsed: true,
    pathname: '/',
    content: null,
  })
  const router = useRouter()

  const toggleNavbar = () => {
    setOptions({
      ...options,
      collapsed: !options.collapsed,
    })
  }

  const getClassName = (url) => {
    // url = "/" + i18n.language + url;
    let mainClass = 'nav-link'
    if (withoutBanner) {
      mainClass = 'nav-link '
    }
    if (url === router.asPath) {
      return `${mainClass} active`
    } else {
      return `${mainClass}`
    }
  }

  const { collapsed } = options
  const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show'
  const classTwo = collapsed
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right'
  return (
    <ErrorBoundary errorComponent={({ error }) => ErrorComponent('NavSimplybook', error)}>
      {ready && (
        <nav
          id="navbar"
          className="navbar navbar-expand-lg navbar-style-one navbar-light bg-light is-sticky"
        >
          <div className="container">
            <Link href="/" className="navbar-brand" locale={i18n.language}>
              <Image
                src="/images/assets/logo-sb.png"
                alt="Simplybook Logo"
                width={50}
                height={50}
              />

              <span className="nav-logo">Simplybook</span>
            </Link>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link href="/" className={getClassName('/')} locale={i18n.language}>
                    {t('Book your vehicle')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    href="/become-a-partner"
                    className={getClassName('/become-a-partner')}
                    locale={i18n.language}
                  >
                    {t('Become a partner')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={getClassName('/blog')} href="/blog" locale={i18n.language}>
                    {t('Travelling in Greece')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/about" className={getClassName('/about')} locale={i18n.language}>
                    {t('About us')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="/faq" className={getClassName('/faq')} locale={i18n.language}>
                    {t('FAQ')}
                  </Link>
                </li>
                <li>
                  <LanguageSwitcher toggleNavbar={toggleNavbar} />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </ErrorBoundary>
  )
}

export default Navbar
