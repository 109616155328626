import { createStore, applyMiddleware, Store, CombinedState } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../src/reducers";

export default function initializeStore(initialState = {}) : Store<CombinedState<any>> {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
}
