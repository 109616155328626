import Navbar from './theme/Layouts/NavbarSimplybook'
import Footer from './theme/Layouts/Footer'
import React from 'react'
import { useTranslation } from 'next-i18next'

const ErrorComponent = (title: string, error: Error) => {
  const { t, ready } = useTranslation()

  const handleGoHome = () => {
    window.location.href = '/'
  }

  return (
    <>
      {ready && (
        <>
          <Navbar withoutBanner={true} />
          <section className="page-title-area jarallax" data-jarallax='{"speed": 0.2}'>
            <div className="container">
              <h2>Error in {title} </h2>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
          </section>

          <section className="error-area ptb-100">
            <div className="container">
              <div className="error-content">
                <button onClick={handleGoHome} className="btn btn-primary">
                  {t('Go Home')}
                </button>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  )
}

export default ErrorComponent
