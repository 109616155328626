const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;

const isServer = typeof window === 'undefined';

module.exports = {
    backend: {
        backendOptions: [
            { loadPath: '/locales/{{lng}}/{{ns}}.json' },
            { expirationTime: 1000 },
            { reloadInterval: true }
        ],
        backends: isServer ? [] : [HttpBackend]
    },
    i18n: {
        defaultLocale: 'en',
        locales: ['en', 'fr', 'de', 'it'],
        localeDetection: true
    },
    serializeConfig: false,
    use: isServer ? [] : [ChainedBackend]
};
