import React, { useEffect } from 'react'

const ErrorComponentRedirectHome = (title:String,error:Error,) => {

  useEffect(() => {

    console.error(`Redirecting to home, error in ${title}`, error)
    window.location.href = '/'
  }, [title,error])

  return (
    <>
    </>)
}

export default ErrorComponentRedirectHome
