import SecureLS from 'secure-ls'
import { nanoid } from 'nanoid'
import { CFHeaders } from '../types'


class LocalStorage {
  private static instance: LocalStorage

  private ls: SecureLS

  private constructor() {
    this.ls = new SecureLS({ encodingType: 'aes' })
  }

  public static getInstance(): LocalStorage {
    if (!LocalStorage.instance && typeof window != 'undefined') {
      LocalStorage.instance = new LocalStorage()
    } else {
      console.warn('Trying to create LocalStorage Instance in the server')
    }
    return LocalStorage.instance
  }

  setItem<K>(name: string, value: K) {
    return this.ls.set(name, value)
  }

  getItem<K>(name: string): K {
    return this.ls.get(name) as K

  }

  public getSessionInfo(): SessionInfo {
    const oldSessionUnknown = this.getItem(SessionInfoKey)
    const oldSessionInfo: SessionInfo | null =
      (isSessionInfo(oldSessionUnknown)) ? oldSessionUnknown as SessionInfo : null
    if (oldSessionInfo == null) {
      const newSessionInfo: SessionInfo = { id: nanoid(6), created: new Date() }
      this.setItem(SessionInfoKey, newSessionInfo)
      return newSessionInfo
    }
    return oldSessionInfo
  }

  public setCFHeaders(cfHeaders: CFHeaders) {

    this.setItem('cf', cfHeaders)

  }

  public getCFHeaders() {
    return this.getItem<CFHeaders>('cf')
  }

  public setSessionEmail(email: string) {
    const oldSessionInfo = appLocalStorage().getSessionInfo()
    const newSession: SessionInfo = { ...oldSessionInfo, email }
    this.setItem(SessionInfoKey, newSession)
    const newSessionInfo = appLocalStorage().getSessionInfo()
  }

  public setRentalId(rentalId: string) {
    console.log('appLocalStorage setRentalId', rentalId)
    const oldSessionInfo = appLocalStorage().getSessionInfo()
    const newSession: SessionInfo = { ...oldSessionInfo, rentalId }
    this.setItem(SessionInfoKey, newSession)
    const newSessionInfo = appLocalStorage().getSessionInfo()
  }

  public resetRentalId() {
    const oldSessionInfo = appLocalStorage().getSessionInfo()
    const { rentalId, ...newSession } = oldSessionInfo
    this.setItem(SessionInfoKey, newSession)
    // console.debug('appLocalStorage resetRentalId, old value:', {rentalId,newSession})
    const newSessionInfo = appLocalStorage().getSessionInfo()
  }


}

const SessionInfoKey = 'sessionInfo'

export interface SessionInfo {
  id: string,
  email?: string,
  created: Date,
  rentalId?: string
}

function isSessionInfo(arg: any): arg is SessionInfo {
  return arg.id !== undefined && arg.created !== undefined
}

export default function appLocalStorage() {
  return LocalStorage.getInstance()
}
