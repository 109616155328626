import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Placeholder, Preloader } from 'react-preloading-screen'
import '../assets/css/animate.min.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/fontawesome.min.css'
import '../assets/css/style.scss'
import Layout from '../src/components/Layout'
import GoTop from '../src/components/theme/Shared/GoTop'
import Loader from '../src/components/theme/Shared/Loader'
import * as gtag from '../src/utils/analytics/google/gtag'
import fb from '../src/utils/analytics/facebook/fb'
import { ApolloProvider } from '@apollo/client'
import { Montserrat } from 'next/font/google'
import client from '../apolloClient'

import { appWithTranslation } from 'next-i18next'
import nextI18nConfig from '../next-i18next.config'
import appLocalStorage from '../src/utils/AppLocalStorage'
import { setNormalMode, setWidgetMode } from '../src/utils/atoms'
import logdna from '@logdna/browser'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../src/components/ErrorComponent'
import ErrorComponentRedirectHome from '../src/components/ErrorComponentRedirectHome'
import { CLARITY_ID } from '../src/globals'
import App from 'next/app'

const customFont = Montserrat({
  subsets: ['latin', 'cyrillic'], // Specify the subsets you want to use
  preload: true, // Enable preloading
  weight: ['400', '500', '600'],
})

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    // Initialization code
    (async function runEffects() {
      appLocalStorage().resetRentalId()
      appLocalStorage().setCFHeaders(pageProps.cfHeaders)

      if (process.env.NEXT_PUBLIC_LOGDNA_ENABLED) {
        const LOGDNA_INGESTION_KEY = process.env.NEXT_PUBLIC_LOGDNA_INGESTION_KEY
        logdna.init(LOGDNA_INGESTION_KEY, {
          console: true,
          app: 'web',
          hostname: process.env.NEXT_PUBLIC_LOG_SOURCE,
        })
        const sessionInfo = appLocalStorage().getSessionInfo()
        logdna.addContext({
          sessionReplay: `https://clarity.microsoft.com/projects/view/${CLARITY_ID}/impressions?UserId=is%25${sessionInfo.id}`,
          sessionInfo,
        })
      }

      async function handleRouteChange(url) {
        gtag.pageview(url);
        (await fb()).pageView()
      }

      Router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange)
      }


    })()
  }, [])

  const router = useRouter()
  useEffect(() => {
    if (router.pathname.match(/\/widget\/.*/)) {
      // console.debug('widget mode')
      setWidgetMode()
    } else {
      // console.debug('normal mode')
      setNormalMode()
    }
  }, [router])

  return (
    <>
      <ErrorBoundary errorComponent={({ error }) => ErrorComponent('ApolloProvider', error)}>
        <ApolloProvider client={client}>
          <ErrorBoundary errorComponent={({ error }) => ErrorComponent('Preloader', error)}>
            <Preloader>
              <ErrorBoundary
                errorComponent={({ error }) => ErrorComponentRedirectHome('Layout', error)}>
                <Layout>
                  <main className={customFont.className}>
                    <div>
                      <Component {...pageProps} />
                    </div>
                  </main>
                </Layout>
              </ErrorBoundary>
              <ErrorBoundary
                errorComponent={({ error }) => ErrorComponent('GoTop', error)}>
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
              </ErrorBoundary>
              <Placeholder>
                <ErrorBoundary
                  errorComponent={({ error }) => ErrorComponent('Loader', error)}>
                  <Loader />
                </ErrorBoundary>
              </Placeholder>
            </Preloader>
          </ErrorBoundary>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const cfIpCountry = appContext.ctx.req?.headers['country'] || 'a'
  const cfConnectingIp = appContext.ctx.req?.headers['ip'] || 'a'

  const cfHeaders = { cfIpCountry, cfConnectingIp }

  // console.debug('MyApp.getInitialProps', {cfHeaders})
  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      cfHeaders,
    },
  }
}

export default appWithTranslation(MyApp, nextI18nConfig)
