//example: https://github.com/vercel/next.js/tree/canary/examples/with-google-analytics
import { Event, EventKey } from './types'
import { Item, PurchaseValue, BeginCheckoutValue } from '../types'
import { SearchVehiclesQuery } from '../../../types'

declare global {
  interface Window {
    gtag: any
  }
}

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GTAG
export const AW_TRACKING_ID = process.env.NEXT_PUBLIC_AW_TRACKING_ID
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (typeof window !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
    window.gtag('config', AW_TRACKING_ID)
  } else {
    console.warn('No window for GA pageview:', url)
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
//https://developers.google.com/gtagjs/reference/ga4-events
function event<K extends EventKey>({ event, value }: Event<K>) {
  if (typeof window !== 'undefined') {
    window.gtag('event', event, value)
  } else {
    console.warn('No window for GA event:', event, ' value:', value)
  }
}

export function searchEvent(query: SearchVehiclesQuery) {
  event({ event: 'search', value: { search_term: JSON.stringify(query) } })
}

export function viewItemEvent(items: Item[]) {
  event({ event: 'view_item', value: { items } })
}

export function selectItemEvent(item: Item) {
  event({ event: 'select_item', value: { items: [item] } })
}

export function beginCheckoutEvent(bcv: BeginCheckoutValue) {
  event({
    event: 'begin_checkout',
    value: bcv,
  })
}

export function purchaseEvent(pv: PurchaseValue) {
  event({
    event: 'purchase',
    value: pv,
  })
}

export function purchaseFailureEvent(pv: PurchaseValue) {
  event({
    event: 'payment_failed',
    value: pv,
  })
}
